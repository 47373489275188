<template>
  <div class="tooltip vue-ctrl" @click.stop="onClick" ref="toolEl">
    <div class="content" v-html="actualContent" v-if="isVisible"></div>
  </div>
</template>

<script>
export default {
  name: "Tooltip",
  props: ["content"],
  data() {
    return {
      isVisible: false,
      actualContent: ""
    }
  },
  computed: {
    tooltipContent() {

    }
  },
  mounted() {
    document.addEventListener('click', this.onClickOutside)
    document.addEventListener('touchstart', this.onClickOutside)
    this.$root.$on("tooltip-displayed", (sender) => {
      if (sender !== this) this.isVisible = false;
    });

  },
  destroyed() {
    document.removeEventListener('click', this.onClickOutside)
    document.removeEventListener('touchstart', this.onClickOutside)
  },
  methods: {
    toggleTooltip() {
      this.isVisible = !this.isVisible;
      if (this.isVisible) {
        this.$root.$emit("tooltip-displayed", this);
        this.$nextTick(() => {
          //we calculate here the left coordinate, so that it does not go outside the screen
          let element = this.$refs.toolEl.querySelector(".content");
          let width = element.offsetWidth;
          let viewportWidth = document.body.offsetWidth;
          let left = element.getBoundingClientRect().left;
          if (left + width > viewportWidth) { //we're assuming here that the tooltip can't be larger than the viewport, due to CSS
            element.style.left = `${viewportWidth - width - left}px`;
          } else {
            element.style.left = "10px";
          }
        });
      }
    },
    onClick(e) {
      e.stopPropagation();
      if (!this.content) return; //we don't display the tooltip if there's no content

      if (typeof (this.content) === "function") {
        //we assume content() returns a promise
        this.content().then(res => {
          this.actualContent = res;
          this.toggleTooltip();
        }).catch(err => {
          console.error(err.message);
        });
      } else {
        this.actualContent = this.content;
        this.toggleTooltip();
      }

    },
    onClickOutside(e) {
      e.stopPropagation()
      if (!this.$refs.toolEl.contains(e.target)) {
        this.isVisible = false;
      }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.tooltip {
  width: 23px;
  height: 23px;
  margin-left: 10px;
  margin-top: 6px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='UTF-8' standalone='no'?%3E%3Csvg xmlns='http://www.w3.org/2000/svg' height='160' width='160' version='1.0'%3E%3Cg fill='%234b4b4b'%3E%3Cpath d='m80 15c-35.88 0-65 29.12-65 65s29.12 65 65 65 65-29.12 65-65-29.12-65-65-65zm0 10c30.36 0 55 24.64 55 55s-24.64 55-55 55-55-24.64-55-55 24.64-55 55-55z'/%3E%3Cpath d='m57.373 18.231a9.3834 9.1153 0 1 1 -18.767 0 9.3834 9.1153 0 1 1 18.767 0z' transform='matrix(1.1989 0 0 1.2342 21.214 28.75)'/%3E%3Cpath d='m90.665 110.96c-0.069 2.73 1.211 3.5 4.327 3.82l5.008 0.1v5.12h-39.073v-5.12l5.503-0.1c3.291-0.1 4.082-1.38 4.327-3.82v-30.813c0.035-4.879-6.296-4.113-10.757-3.968v-5.074l30.665-1.105'/%3E%3C/g%3E%3C/svg%3E");
  background-size: 23px 23px;
  position: relative;
  cursor: pointer;
}

.content {
  position: absolute;
  max-width: 600px;
  min-width: 300px;
  bottom: 30px;
  box-shadow: 3px 3px 3px 0px rgba(100, 100, 100, 0.75);
  border-radius: 5px;
  padding: 10px;
  background-color: #2655FF;
  color: white;
  font-size: 80%;
  z-index: 100;
  overflow-wrap: break-word;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
}

@media screen and (max-width: 600px) {
  .tooltip {
    position: relative;
  }

  .content {
    position: absolute;
    left: 10px;
    right: 10px;
    max-width: 95vw;
  }
}
</style>