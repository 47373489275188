var Config = {
    initialized: false,
    resUrl: "/resources/additives-fr-goals",
    searchPage: "/Corporate/Search",
    searchTarget: "_self",
    fixedFilter: "5386a6b9f9a84aa983c6fecedd43018d",
    selectorButtons: [],
    isFlameRetardantsPage: "true",
    init() {
        if (this.initialized) return;
        
        this.resUrl = this.getElementValue("resurl", this.resUrl);
        this.searchPage = this.getElementValue("searchPage", this.searchPage);
        this.searchTarget = this.getElementValue("searchTarget", this.searchTarget);
        this.fixedFilter = this.getElementValue("fixedFilter", this.fixedFilter);
        this.isFlameRetardantsPage = this.getElementValue("isFlameRetardantsPage", this.isFlameRetardantsPage);
        let selBtns = document.querySelectorAll("#selector-buttons > span");
        for (let i = 0; i < selBtns.length; i++) {
            this.selectorButtons.push({
                label: selBtns[i].dataset.label || "Label unspecified",
                url: selBtns[i].dataset.url || "/",
                target: selBtns[i].dataset.target || "_blank",
            });
        }
        
        this.initialized = true;
    },
    getElementValue(id, defaultValue) {
        var el = document.getElementById(id);
        if (el) return el.value || defaultValue;
        else return defaultValue;
    }
};

export default Config;