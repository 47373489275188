// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf8,%3C?xml version=%271.0%27 encoding=%27UTF-8%27 standalone=%27no%27?%3E%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 height=%27160%27 width=%27160%27 version=%271.0%27%3E%3Cg fill=%27%234b4b4b%27%3E%3Cpath d=%27m80 15c-35.88 0-65 29.12-65 65s29.12 65 65 65 65-29.12 65-65-29.12-65-65-65zm0 10c30.36 0 55 24.64 55 55s-24.64 55-55 55-55-24.64-55-55 24.64-55 55-55z%27/%3E%3Cpath d=%27m57.373 18.231a9.3834 9.1153 0 1 1 -18.767 0 9.3834 9.1153 0 1 1 18.767 0z%27 transform=%27matrix%281.1989 0 0 1.2342 21.214 28.75%29%27/%3E%3Cpath d=%27m90.665 110.96c-0.069 2.73 1.211 3.5 4.327 3.82l5.008 0.1v5.12h-39.073v-5.12l5.503-0.1c3.291-0.1 4.082-1.38 4.327-3.82v-30.813c0.035-4.879-6.296-4.113-10.757-3.968v-5.074l30.665-1.105%27/%3E%3C/g%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".tooltip[data-v-671793b0]{width:23px;height:23px;margin-left:10px;margin-top:6px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:23px 23px;position:relative;cursor:pointer}.content[data-v-671793b0]{position:absolute;max-width:600px;min-width:300px;bottom:30px;-webkit-box-shadow:3px 3px 3px 0 hsla(0,0%,39%,.75);box-shadow:3px 3px 3px 0 hsla(0,0%,39%,.75);border-radius:5px;padding:10px;background-color:#2655ff;color:#fff;font-size:80%;z-index:100;overflow-wrap:break-word;font-family:Gotham SSm A,Arial,Helvetica,sans-serif}@media screen and (max-width:600px){.tooltip[data-v-671793b0]{position:relative}.content[data-v-671793b0]{position:absolute;left:10px;right:10px;max-width:95vw}}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
