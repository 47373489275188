<template>
  <div class="cla-textbox-container vue-ctrl" 
    :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false, invalid: typeof(invalid) !== 'undefined' && invalid !== false}" >
    <div class="label-container"><label :for="uniqueId" class="textbox-label" v-if="label">{{label}}</label><Tooltip :content="tooltip" v-if="tooltip"></Tooltip></div>
    <div class="control-container">
      <div class="input-container">
        <input
          :type="typeof(password) !== 'undefined' && password !== false ? 'password': 'text'"
          :placeholder="placeholder"
          v-model="currentText"
          @change="onChange"
          v-on:keyup.enter="onChange"
          :readonly="typeof(readonly) !== 'undefined' && readonly !== false" :maxlength="maxlength"
          :id="uniqueId"
        >
      </div>
    </div>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
import Utils from "../services/utils";
export default {
  name: "TextBox",
  props: [
    "label",
    "mandatory",
    "value",
    "placeholder",
    "tooltip",
    "invalid",
    "readonly",
    "description",
    "validation-message",
    "maxlength",
    "password"
  ],
  components: { Tooltip },
  data() {
    return {
      currentText: this.value
    };
  },
  computed : {
    uniqueId () {
      return this.id || Utils.getComponentId(this);
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.currentText);
      this.$emit("change", this.currentText);
    }
  },
  watch: {
    value (val) {
      this.currentText = val;
    }
  }
};
</script>

<style scoped>
.cla-textbox-container {
  width: 100%;
  margin-bottom: 10px;
}

.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-textbox-container .textbox-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  line-height: 26px;
  font-weight: 500;
}

.cla-textbox-container .textbox-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-textbox-container.mandatory .textbox-label:after {
  content: "*";
}

.cla-textbox-container .control-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  position: relative;
}

.cla-textbox-container .input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  background-color: #e4e4e4;
  padding: 4px 16px;
  box-sizing: border-box;
  display: flex;
  align-self: center;
}

.cla-textbox-container.invalid .input-container {
  background-color: rgb(245, 201, 201);
}

.cla-textbox-container .input-container input {
  border: none;
  flex-grow: 1;
  background-color: #e4e4e4;
  font-size: 16px;
  line-height: 19px;
}

.cla-textbox-container.invalid .input-container input{
  background-color: rgb(245, 201, 201);
}


.cla-textbox-container input::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}

/* clears the 'X' from Internet Explorer */
.cla-textbox-container input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.cla-textbox-container input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
.cla-textbox-container input[type="search"]::-webkit-search-decoration,
.cla-textbox-container input[type="search"]::-webkit-search-cancel-button,
.cla-textbox-container input[type="search"]::-webkit-search-results-button,
.cla-textbox-container input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.cla-textbox-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-textbox-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-textbox-container.invalid .description-container {
  display: none;
}

.cla-textbox-container.invalid .validation-error {
  display: block;
}
</style>