import Vue from "vue";

import CheckboxGroup from "./components/CheckboxGroup.vue";
import Checkbox from "./components/Checkbox.vue";
import RadioButtonGroup from "./components/RadioButtonGroup.vue";
import RadioButton from "./components/RadioButton.vue";
import DropList from "./components/DropList.vue";
import Slider from "./components/Slider.vue";
import FlatButton from "./components/FlatButton.vue";
import TextBox from "./components/TextBox.vue";
import NoteBox from "./components/NoteBox.vue";
import FilePicker from "./components/FilePicker.vue";
import TreeList from "./components/TreeList.vue";
import Tooltip from "./components/Tooltip.vue";
import DatePicker from "./components/DatePicker.vue";
import RichTextBox from "./components/RichTextBox.vue";
import TagList from "./components/TagList.vue";
import ListBox from "./components/ListBox.vue";

const Components = {
    CheckboxGroup,
    Checkbox, 
    RadioButtonGroup,
    RadioButton, 
    DropList,
    Slider,
    FlatButton,
    TextBox,
    NoteBox,
    FilePicker,
    TreeList,
    Tooltip,
    DatePicker,
    RichTextBox,
    TagList,
    ListBox
};

export default Components;
export {
    CheckboxGroup,
    Checkbox, 
    RadioButtonGroup,
    RadioButton, 
    DropList,
    Slider, 
    FlatButton,
    TextBox,
    NoteBox,
    FilePicker,
    TreeList,
    Tooltip,
    DatePicker,
    RichTextBox,
    TagList,
    ListBox
};

