<template>
  <li class="tag">
    <div class="tag-name">{{tag.name}}</div>
    <a class="tag-remove" @click="onRemove" v-if="typeof(readonly) === 'undefined'"></a>
  </li>
</template>

<script>
export default {
  name: "TagListItem",
  props: ["tag", "readonly"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onRemove() {
      this.$emit("remove", this.$props.tag);
    }
  }
};
</script>

<style scoped>
.tag {
  background-color: #3498db;
  border-radius: 3px;
  display: flex;
  align-items: center; 
  padding: 4px 10px;
  margin: 0 10px 10px 0;
}

.tag:last-of-type {
  margin-right: 0px;
}

.tag > .tag-name {
    color: white;
    font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.tag > .tag-remove {
    width: 12px;
    height: 12px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='357' height='357'%3E%3Cpath fill='white' d='M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8z'/%3E%3C/svg%3E");
    background-size: 12px 12px;
    margin-left:10px;
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
}
</style>