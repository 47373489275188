<template>
  <li class="list-item">
    <div class="list-item-name" :class="{uppercase: typeof(uppercase) !== 'undefined'}">{{item.name}}</div>
    <a class="list-item-remove" @click="onRemove" v-if="typeof(readonly) === 'undefined'"></a>
  </li>
</template>

<script>
export default {
  name: "ListBoxItem",
  props: ["item", "readonly", "uppercase"],
  data() {
    return {};
  },
  mounted() {},
  methods: {
    onRemove() {
      this.$emit("remove", this.$props.item);
    }
  }
};
</script>

<style scoped>
.list-item {
  display: flex;
  align-items: center; 
  padding: 10px 0px;
  margin: 0 0 0 0;
  border-top: 1px solid #c9c9c9;
}

.list-item:first-of-type {
  border-top: none;
}

.list-item > .list-item-name {
    font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
    font-size: 12px;
    font-weight: 400;
    color: #323232;
    flex-grow: 1;
    padding-left: 10px;
}

.list-item > .list-item-remove {
    width: 12px;
    height: 12px;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='357' height='357'%3E%3Cpath fill='%23aaa' d='M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8z'/%3E%3C/svg%3E");
    background-size: 12px 12px;
    margin-left:10px;
    cursor: pointer;
    background-position: 50%;
    background-repeat: no-repeat;
    padding-right: 10px;
}
</style>