<template>
  <div class="selector">
    <SelectorButton blue :label="labels.productInfo" @click="$emit('toggle-product-info')" class="frgoals-button"></SelectorButton>
    <SelectorButton blue v-for="btn in config.selectorButtons" :key="btn.label" @click="navigateTo(btn)" :label="btn.label" class="frgoals-button"></SelectorButton>
  </div>
</template>

<script>
import {Labels, Config} from "../services";
import SelectorButton from "./SelectorButton";

export default {
  name: "Selector",
  components: {
    SelectorButton
  },
  data () {
    return {
      labels: Labels,
      config: Config
    }
  },
  methods: {
    navigateTo(btn) {
      if (!btn) return;
      window.open(btn.url, btn.target || "_self");
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selector {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.selector > .button {
  margin-right: 50px;
}

@media all and (max-width: 1024px) {
  .selector {
    justify-content: space-between;
  }
  .selector > .button {
    margin-right: 50px;
}
}

@media all and (max-width: 720px) {
 .selector > .button {
  flex-grow: 1;
  width: 100%;
  margin-right: 0;
} 
}
</style>
