<template>
  <div class="cla-taglist-container vue-ctrl" :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false}">
    <div class="label-container">
      <div class="taglist-label" v-if="label">{{label}}</div>
      <Tooltip :content="tooltip" v-if="tooltip"></Tooltip>
    </div>
    <div class="control-container" :style="'height:' + controlHeight">
      <ul>
        <TagListItem
          v-for="tag in tagData"
          :key="tag.id"
          :tag="tag"
          :readonly="readonly"
          @remove="onRemoveTag"
        ></TagListItem>
      </ul>
      <div class="placeholder" v-if="!tagData || tagData.length === 0">{{placeholder}}</div>
    </div>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import TagListItem from "./TagListItem";
import Tooltip from "./Tooltip";
export default {
  name: "TagList",
  props: [
    "label",
    "mandatory",
    "height",
    "tooltip",
    "tags",
    "readonly",
    "placeholder",
    "description",
    "validation-message"
  ],
  components: {
    TagListItem,
    Tooltip
  },
  data() {
    return {
      tagData: this.$props.tags || [],
      controlHeight: this.height || "auto"
    };
  },
  mounted() {},
  methods: {
    onRemoveTag(tag) {
      if (!tag) return;
      this.$emit("remove", tag);
    }
  },
  watch: {
    tags(val) {
      this.tagData = val;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-taglist-container {
  width: 100%;
  margin-bottom: 10px;
}

.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-taglist-container .taglist-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-taglist-container .taglist-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-taglist-container.mandatory .taglist-label:after {
  content: "*";
}

.cla-taglist-container .control-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: #e4e4e4;
  padding: 16px 16px 6px 16px;
  overflow-y: auto;
  min-height: 64px;
}

.cla-taglist-container .control-container > ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
}

.cla-taglist-container .control-container > .placeholder {
  text-align: center;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  color: #888;
  font-size: 13px;
  margin-top: 6px;
}

.cla-taglist-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-taglist-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-taglist-container.invalid .description-container {
  display: none;
}

.cla-taglist-container.invalid .validation-error {
  display: block;
}
</style>