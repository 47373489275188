<template>
  <div id="app">
    <div class="layout-wrapper fullwidth">
      <div class="content-wrapper">
        <div class="intro">
        <div class="serif-large">{{labels.heading1}}<br>
        <span class="sans-serif-large">{{labels.heading2}}</span></div>
        <p>{{labels.intro}}</p>
        </div>
        <Selector @toggle-product-info="showProducts()"/> 
      </div>
    </div>
    <div class="layout-wrapper fullwidth tabs" v-if="showProductInfo">
      <div class="content-wrapper">
        <ProductInfo />
      </div>
    </div>
  </div>
</template>

<script>
import Selector from "./components/Selector.vue";
import ProductInfo from "./components/ProductInfo.vue";
import {Labels, Config} from "./services";

export default {
  name: "App",
  components: {
    Selector, ProductInfo
  },
  data () {
    return {
      labels: Labels,
      showProductInfo: false
    }
  },
  mounted () {
    Config.init();
    Labels.init();
  },
  methods: {
    showProducts(){
      this.showProductInfo = !this.showProductInfo
      if(this.showProductInfo){
      this.$nextTick(() => {
        let productSearch = document.querySelector("div.product-search")
        productSearch.scrollIntoView({block: "end", behavior: "smooth"});
      })
      }
    }
  }
};
</script>

<style>
#app {
  font-family: "Gotham SSm A", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: block;
}

#app > .layout-wrapper {
  background-color: #D5EDF9;
}

#app > .layout-wrapper > .content-wrapper {
  padding: 40px;
}

#app > .layout-wrapper.tabs {
  margin-top: 20px;
}

.intro > p {
  width: 480px;
  max-width: 100%;
  margin-bottom: 60px;
}

.frgoals-button {
  width: 150px;
}

.stretch {
  flex-grow: 1;
}

.cla-messagebox {
  position: fixed;
  top: 100px;
  right: 0;
  background-color: #ffffff;
  max-width: 400px;
  padding: 20px;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  box-shadow: 3px 3px rgba(200, 200, 200, 0.4);
  border-left: 1px solid #e4e4e4;
  animation: slide 0.5s forwards;
  transition: opacity 500ms linear;
  opacity: 1;
  z-index: 10001;
}

@keyframes slide {
  0% {
    right: 0;
  }
  100% {
    right: 100px;
  }
}

.cla-messagebox.green {
  border-top: 3px solid green;
}

.cla-messagebox.yellow {
  border-top: 3px solid yellow;
}

.cla-messagebox.red {
  border-top: 3px solid red;
}

#app .cla-droplist-container .input-container,
#app .cla-droplist-container .input-container > input {
  background-color: #3394D9 !important;
}
/* Overwrite styling of DropList */

#app .cla-droplist-container .input-container > input::placeholder
{
  color: white;
  font-family: Gotham SSm A,Arial,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: 500;
}

#app .cla-droplist-container .input-container > input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
  font-family: Gotham SSm A,Arial,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: 500;
}

#app .cla-droplist-container .input-container > input::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
  font-family: Gotham SSm A,Arial,Helvetica,sans-serif;
  font-size: 18px;
  font-weight: 500;
}

#app .cla-droplist-container .input-container a.cla-icon-link-drop {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='iso-8859-1'?%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='255px' fill='white' height='255px' viewBox='0 0 255 255'%3E%3Cg%3E%3Cg id='arrow-drop-down'%3E%3Cpolygon points='0,63.75 127.5,191.25 255,63.75'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E") !important;
}

#app .cla-droplist-container .options-container.open {
  background-color: #F1F9FC;
  border-color: #3394D9 !important;
}


/* Overwrite styling of RadioButton */

#app .cla-radio .radiomark {
  background-color: #3394D9 !important;
  border-color: #3394D9 !important;
}

#app .cla-radio input[type=radio]:checked+.radiomark:after {
  background-color: white !important;
}

#app .cla-radio .radiolabel {
  font-weight: 700 !important;
  font-size: 18px !important;
  color: #323232;
}

#app .radio-container {
  padding: 30px 0px;
}

#app .radio-container > a {
  margin: 20px 0px;
}
</style>
