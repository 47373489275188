'use strict';
import Labels from "./labels";
import Config from "./config";

let utils = {
  eventQueue: [],
  retryCount: 0,
  timer: null,
  getIsoDate(src) {
    if (!src) return "";
    let date = null;
    if (typeof(src) === "object") date = src;
    else date = new Date(src);
    return `${date.getFullYear()}-${(this.twoDigits(date.getMonth() + 1))}-${this.twoDigits(date.getDate())}`
  },
  getIsoDateTime(src) {
    if (!src) return "";
    let date = null;
    if (typeof(src) === "object") date = src;
    else date = new Date(src);
    return `${date.getFullYear()}-${(this.twoDigits(date.getMonth() + 1))}-${this.twoDigits(date.getDate())} ${this.twoDigits(date.getHours())}-${(this.twoDigits(date.getMinutes()))}-${this.twoDigits(date.getSeconds())}`
  },
  getLocaleDate(src) {
    if (!src) return "";
    let date = null;
    if (typeof(src) === "object") date = src;
    else date = new Date(src);
    return `${this.twoDigits(date.getDate())}.${(this.twoDigits(date.getMonth() + 1))}.${date.getFullYear()}`;
  },
  getLocaleDateTime(src) {
    if (!src) return "";
    let date = null;
    if (typeof(src) === "object") date = src;
    else date = new Date(src);
    return `${this.twoDigits(date.getDate())}.${(this.twoDigits(date.getMonth() + 1))}.${date.getFullYear()} ${this.twoDigits(date.getHours())}:${this.twoDigits(date.getMinutes())}:${this.twoDigits(date.getSeconds())}`;
  },
  twoDigits(x) {
    if (x < 10) return `0${x}`;
    else return `${x}`;
  },
  showStatusMessage (msg, color, duration) {
    if (!msg) return;
    color = color || "green";
    duration = duration | 5000;
    let messageBox = document.createElement("div");
    messageBox.classList.add("cla-messagebox");
    messageBox.classList.add(color);
    messageBox.innerHTML = msg;
    document.body.appendChild(messageBox);
    setTimeout(() => {
      if (messageBox) {
        messageBox.style.opacity = '0';
        setTimeout(() => {
          messageBox.remove();
        }, 500);
      }
    }, duration);
  },
  showLoader (element, showAtTheTop) {
    let el = typeof(element) === "string" ? document.querySelector(element) : element;
    if (!el) {
      console.warn(`Could not create loader in the element ${typeof(element)==="string" ? element : element.name}`);
      return;
    }
    let loader = document.createElement("div");
    loader.classList.add("loader");
    if (showAtTheTop) loader.classList.add("top");
    el.appendChild(loader);
    return loader;
  },
  removeLoader (loader) {
    if (!loader) return;
    if (!loader.parentNode) return;
    loader.parentNode.removeChild(loader);
  },
  getCookie(name) {
      var regexp = new RegExp("(?:^" + name + "|;\s*"+ name + ")=(.*?)(?:;|$)", "g");
      var result = regexp.exec(document.cookie);
      return (result === null) ? "" : result[1];
  },
  shortIdToGuid(input) {
    if (!input || input.length !== 32) return;
    let output = `{${input.substring(0, 8)}-${input.substring(8, 12)}-${input.substring(12, 16)}-${input.substring(16, 20)}-${input.substring(20, 32)}}`;
    return output.toUpperCase();
  },
  makeSafe(src) {
    //we replace characters not safe for paths with spaces
    if (!src) return "";
    return src.replace(/[^a-z0-9]/gi, ' ');
  },
  getResultValues(res) {
    let ret = [];
    res.map(x => ret.push(x.count));
    return ret;
  },
  getResultNames(res) {
    let ret = [];
    res.map(x => ret.push(x.name));
    return ret;
  },
  saveDownloadedFile(data, fileName) {
    if (data) {
      var blob = new Blob([data]);
      var link = document.createElement('a');
      if (window.navigator.msSaveOrOpenBlob) {
          link.onclick = function(e) {
              window.navigator.msSaveOrOpenBlob(blob, fileName);
          }
      } else {
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
      }

      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
    }
  },
  openSearchPage(tagId) {
    let url = `${Config.searchPage}?tab=products&tag=${tagId}&tag=${Config.fixedFilter}`;
    window.open(url, Config.searchTarget);
  },
  openSearchQuery(query){
    let url = `${Config.searchPage}?q=${query}&tag=${Config.fixedFilter}`;
    window.open(url, Config.searchTarget);
  }
};

export default utils;