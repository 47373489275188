<template>
  <div class="product-search">
    <h5>{{ labels.productInfoTitle }}</h5>
    <p>{{ labels.productInfoIntro }}</p>
    <!-- Flame Retardants -->
    <div v-if="config.isFlameRetardantsPage === 'true'" class="radio-container">
      <RadioButton
        :label="labels.byProductName"
        v-model="selectedTab"
        value="1"
      ></RadioButton>
      <RadioButton
        :label="labels.byIndustry"
        v-model="selectedTab"
        value="2"
      ></RadioButton>
      <RadioButton
        :label="labels.byApplication"
        v-model="selectedTab"
        value="3"
      ></RadioButton>
      <RadioButton
        :label="labels.byCompound"
        v-model="selectedTab"
        value="4"
      ></RadioButton>
    </div>
    <!-- Waxes -->
    <div v-else class="waxes radio-container">
      <RadioButton
        :label="labels.byProductName"
        v-model="selectedTab"
        value="1"
      ></RadioButton>
      <RadioButton
        :label="labels.byFunction"
        v-model="selectedTab"
        value="5"
      ></RadioButton>
      <RadioButton
        :label="labels.byMaterial"
        v-model="selectedTab"
        value="6"
      ></RadioButton>
    </div>
    <div class="tab-container">
      <KeywordSearch v-if="selectedTab === '1'"></KeywordSearch>
      <IndustrySearch v-if="selectedTab === '2'"></IndustrySearch>
      <ApplicationSearch v-if="selectedTab === '3'"></ApplicationSearch>
      <CompoundSearch v-if="selectedTab === '4'" :placeholder="labels.selectPolymer" type="compounds" key="compounds"></CompoundSearch>
      <CompoundSearch v-if="selectedTab === '5'" :placeholder="labels.selectFunction" type="functions" key="functions"></CompoundSearch>
      <CompoundSearch v-if="selectedTab === '6'" :placeholder="labels.selectMaterial" type="materials" key="materials"></CompoundSearch>
    </div>
  </div>
</template>

<script>
import { RadioButton } from "clariant-vue-forms";
import { Labels, Config } from "../services";
import KeywordSearch from "./KeywordSearch";
import IndustrySearch from "./IndustrySearch";
import ApplicationSearch from "./ApplicationSearch";
import CompoundSearch from "./CompoundSearch";

export default {
  name: "Selector",
  components: {
    RadioButton,
    KeywordSearch,
    IndustrySearch,
    ApplicationSearch,
    CompoundSearch,
  },
  data() {
    return {
      labels: Labels,
      config: Config,
      selectedTab: "1",
    };
  },
  methods: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.selector {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.selector > .button {
  width: 150px;
  margin-right: 25px;
}

.selector > .button:last-of-type {
  margin-right: 0px;
}

.radio-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.radio-container > a {
    width: 48%;
  }

.waxes.radio-container {
  flex-direction: column;
}

.tab-container {
  margin-top: 40px;
  width: 100%;
}

@media all and (max-width: 720px) {
  .radio-container > a {
    width: 100%;
  }
}
</style>
