<template>
  <div class="cla-radio-group vue-ctrl" 
    :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false, inline: typeof(inline) !== 'undefined' && inline !== false}">
    <div class="label-container"><div class="group-label" v-if="label">{{label}}</div><Tooltip :content="tooltip" v-if="tooltip"></Tooltip></div>
    <div class="radio-container">
      <slot></slot>
    </div>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
export default {
  name: "RadioButtonGroup",
  props: ["label", "mandatory", "inline", "name", "tooltip", "description",
    "validation-message"],
  components: { Tooltip }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-radio-group {
    font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
    font-size: 18px;
    /*font-weight: 500;*/
    margin-bottom: 10px;
}
.cla-radio-group.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.cla-radio-group .group-label {
    font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
    font-size: 18px;
    font-weight: 500;
}

.cla-radio-group .radio-container {
    display: flex;
    flex-direction: column;
}

.cla-radio-group.inline .radio-container {
    display: flex;
    flex-direction: row;
    width: 100%;
    flex-wrap: wrap;
}

.cla-radio-group.mandatory .group-label:after {
    content: " ";
    white-space: pre;
    color: red;
    vertical-align: super;
    font-size: 80%;
}

.cla-radio-group.mandatory .group-label:after {
    content: "*";
}

.cla-radio-group.inline .radio-container .cla-radio{
    margin-right: 20px;
}

 @media all and (max-width:600px) {
   .cla-radio-group {
     width: 100%;
   }
 }

 .cla-radio-group .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-radio-group .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-radio-group.invalid .description-container {
  display: none;
}

.cla-radio-group.invalid .validation-error {
  display: block;
}
</style>