<template>
  <a class="cla-radio vue-ctrl" @click="onClick">
    <input type="radio" :id="uniqueId" :name="name" :value="value" :checked="isChecked"/>
    <div class="radiomark"></div>
    <label :for="uniqueId" class="radiolabel">{{label}}</label>
    <Tooltip :content="tooltip" v-if="tooltip"></Tooltip>
  </a>
</template>

<script>
import Tooltip from "./Tooltip";
import Utils from "../services/utils";
export default {
  name: "RadioButton",
  model: {
    prop: 'modelValue',
    event: 'input'
  },
  props: ["id", "name", "label", "value", "tooltip", "modelValue"],
  components: {
    Tooltip
  },
  data () {
    return {
      isChecked: false
    }
  },
  methods: {
    onClick: function(e) {
      this.$emit("input", this.value);
      this.$emit("change", {sender: this});
    }
  },
  mounted() {
    this.isChecked = this.modelValue === this.value;
  },
  computed : {
    uniqueId () {
      return this.id || Utils.getComponentId(this);
    }
  },
  watch: {
    modelValue(val) {
      this.isChecked = val === this.value;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-radio {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: black;
    font-weight: 400;
    margin-bottom: 5px;
}

.cla-radio .radiomark {
    width: 24px;
    min-width: 24px;
    height: 24px;
    min-height: 24px;
    border: 1px solid #AAAAAA;
    background-color: #EEEEEE;
    border-radius: 50%;
    margin-right: 20px;
    position: relative;
    box-sizing:content-box;
}

.cla-radio input[type=radio]:checked+.radiomark:after {
    content:'';
    top: 4px;
    left: 4px;
    position: absolute;
    width: 16px;
    height: 16px;
    background-color: black;
    border-radius: 50%;    
}

.cla-radio .radiolabel {
    font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 100;
}

.cla-radio > input[type=radio] {
    display:none;
}

.cla-radio > .tooltip {
  margin-top: 0;
}
</style>