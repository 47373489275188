<template>
  <a class="button vue-ctrl" :class="{ blue: blue, white }" @click="onClick">
    {{ label }}<br />
    <!-- <img class="arrow-down" :src="arrowDown" /> -->
    <svg
      width="40"
      height="50"
      viewBox="0 0 103 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2 2L49.5892 31.8034C50.4126 32.319 50.8243 32.5768 51.2767 32.6277C51.4251 32.6443 51.5749 32.6443 51.7233 32.6277C52.1757 32.5768 52.5874 32.319 53.4108 31.8034L101 2"
        :stroke="blue ? 'white' : '#0071B7'"
        stroke-width="4"
        stroke-linejoin="round"
      />
    </svg>
  </a>
</template>

<script>
import { Config } from "../services";

export default {
  name: "SelectorButton",
  props: {
    label: String,
    white: Boolean,
    blue: Boolean,
  },
  data() {
    return {};
  },
  methods: {
    onClick() {
      this.$emit("click", { sender: this });
    },
  },
};
</script>

<style scoped>
.button {
  padding-top: 50px;
  width: 200px;
}
.arrow-down {
  width: 40px;
  height: 50px;
  margin-top: 10px;
}
</style>