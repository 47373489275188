<template>
  <div class="cla-checkbox-group vue-ctrl" 
    :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false, inline: typeof(inline) !== 'undefined' && inline !== false}">
    <div class="label-container"><div class="group-label" v-if="label">{{label}}</div><Tooltip :content="tooltip" v-if="tooltip"></Tooltip></div>
    <div class="checkbox-container">
      <slot></slot>
    </div>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
export default {
  name: "CheckboxGroup",
  props: ["label", "mandatory", "inline", "tooltip","description",
    "validation-message"],
  components: {
    Tooltip
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-checkbox-group {
  margin-bottom: 10px;
}
.cla-checkbox-group.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}
.cla-checkbox-group .group-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-checkbox-group .checkbox-container {
  display: flex;
  flex-direction: column;
}

.cla-checkbox-group.inline .checkbox-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.cla-checkbox-group.inline .checkbox-container .cla-checkbox {
  margin-right: 20px;
}

.cla-checkbox-group .group-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-checkbox-group.mandatory .group-label:after {
  content: "*";
}

@media all and (max-width: 600px) {
  .cla-checkbox-group {
    width: 100%;
  }
}

.cla-checkbox-group .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-checkbox-group .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-checkbox-group.invalid .description-container {
  display: none;
}

.cla-checkbox-group.invalid .validation-error {
  display: block;
}
</style>