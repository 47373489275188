const Utils = {
    getComponentId(component) {
        if (!component) return "unspecified-id";
        let appId = "";
        if (!component.$root.$children || component.$root.$children === 0) appId = "0";
        else {
            appId = component.$root.$children[0]._uid;
        }
        return `${component.$vnode.tag.toLowerCase()}-${appId}-${component._uid}`;
    }
};

export default Utils;