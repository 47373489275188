<template>
  <div class="keyword-search">
        <div class="prod-search-container">
            <input type="search" :placeholder="labels.productSearchPlaceholder" v-model="query" v-on:keydown.enter="search">
            <a href="#" class="icon-link icon-link-search-blue" @click="search"><span></span></a>
        </div>
        <FlatButton :label="labels.search" blue class="frgoals-button"  @click="search"></FlatButton>
  </div>
</template>

<script>
import { FlatButton } from "clariant-vue-forms";
import { Labels, Config, Utils } from "../services";

export default {
  name: "KeywordSearch",
  components: {
    FlatButton,
  },
  data() {
    return {
      labels: Labels,
      config: Config,
      query: ''
    };
  },
  methods: {
    search(){
      Utils.openSearchQuery(this.query);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.keyword-search {
  display: flex;
  align-items: center;
}
.prod-search-container {
  flex-grow: 1;
  margin-right: 50px;
  display: flex;
  border-bottom: 2px solid #3394D9;
  background-color: transparent;
  padding: 5px 0;
}

.prod-search-container > input {
    width: calc(100% - 50px);
    border: none;
    font-family: "Gotham SSm A", Arial, sans-serif;
    font-weight: 700;
    font-size: 17px;
    outline: none;
    background-color: transparent;
}

.prod-search-container > input::placeholder {
    color: #323232;
}

.prod-search-container > input:-ms-input-placeholder {
    color: #323232;
}

.prod-search-container > input::-ms-input-placeholder {
    color: #323232;
}

.prod-search-container > a {
  width: 50px;
  padding: 0;
  background-size: 20px 20px;
  background-position-x: 30px;
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='utf-8'?%3E%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' viewBox='0 0 1000 1000' enable-background='new 0 0 1000 1000'%3E%3Cg%3E%3Cpath fill='%233394D9' d='M960.9,820.2c18,18,29.1,42.9,29.1,70.3c0,54.9-44.5,99.5-99.4,99.5c-27.5,0-52.3-11.1-70.3-29.1l0,0L609.6,750.2c-59.2,35-128,55.4-201.8,55.4C188.1,805.6,10,627.5,10,407.8C10,188.1,188.1,10,407.8,10c219.7,0,397.8,178.1,397.8,397.8c0,73.7-20.4,142.6-55.4,201.8L960.9,820.2L960.9,820.2z M407.8,109.4C243,109.4,109.4,243,109.4,407.8c0,164.8,133.6,298.3,298.3,298.3s298.3-133.6,298.3-298.3C706.1,243,572.6,109.4,407.8,109.4z'/%3E%3C/g%3E%3C/svg%3E");
}

@media all and (max-width: 720px) {
  .frgoals-button{
    display: none;
  }

  .prod-search-container {
    margin-right: 0px;
  } 
}
</style>
