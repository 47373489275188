<template>
  <div class="cla-tree-container vue-ctrl" :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false}" >
    <div class="label-container"><div class="tree-label" v-if="label">{{label}}</div><Tooltip :content="tooltip" v-if="tooltip"></Tooltip></div>
    <ul class="control-container" :style="'height:' + controlHeight">
      <TreeNode :data="treeData" :dataFnc="dataFnc" :labels="labelField" :values="valueField" @change="onSelectionChange" @dblclick="onDoubleClick" :leaf="leafField"></TreeNode>
    </ul>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import TreeNode from "./TreeNode";
import Tooltip from "./Tooltip";
export default {
  name: "TreeList",
  props: [
    "label",
    "mandatory",
    "data",
    "dataFnc",
    "value",
    "labels",
    "leaf",
    "values",
    "height",
    "tooltip",
    "description",
    "validation-message"
  ],
  components: {
    TreeNode, Tooltip
  },
  data() {
    return {
      labelField: this.labels || "name",
      valueField: this.values || "id",
      leafField: this.leaf || "isLeaf",
      selectedValue: this.value,
      treeData: this.data || {},
      controlHeight: this.height || "400px",
      treelist: this
    };
  },
  mounted() {
    if (!this.data && !this.dataFnc) {
        console.error(`The tree list with label ${this.label} is misconfigured`);
    }
  },
  methods: {
    onSelectionChange(e) {     
      this.$emit("input", e.value);
      this.$emit("change", {sender: this, value: e.value, label: e.label} );
    },
    onDoubleClick(e) {     
      this.$emit("dblclick", {sender: this, value: e.value, label: e.label} );
    }
  },
  watch: {
    value (val) {
      this.currentText = val;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-tree-container {
  width: 100%;
  margin-bottom: 10px;
}

.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-tree-container .tree-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-tree-container .tree-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-tree-container.mandatory .tree-label:after {
  content: "*";
}

.cla-tree-container .control-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: #e4e4e4;
  padding: 4px 16px 4px 0;
  overflow-y: auto;
}

.cla-tree-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-tree-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-tree-container.invalid .description-container {
  display: none;
}

.cla-tree-container.invalid .validation-error {
  display: block;
}
</style>