<template>
  <div class="industry-search">
    <div class="teasers-container">
      <div v-for="industry in industries" :key="industry.id" class="teaser-box" @click="openSearchPage(industry.id)">
        <img class="teaser-image" :src="industry.image"/>
        <h4 class="teaser-title">{{industry.name}}</h4>
      </div>
    </div>
  </div>
</template>

<script>
import { FlatButton } from "clariant-vue-forms";
import { Labels, Config, Utils, DataLayer } from "../services";

export default {
  name: "IndustrySearch",
  components: {
    FlatButton,
  },
  data() {
    return {
      labels: Labels,
      config: Config,
      industries: null,
    };
  },
  mounted () {
    let loader = Utils.showLoader(".industry-search");
    DataLayer.getApplications().then(res => {
      this.industries = res.data;
    }).catch (err => {
      Utils.showStatusMessage(`Unable to load industries: ${err}!`, "red");
    }).finally (() => {
      Utils.removeLoader(loader);
    });
  },
  methods: {
    openSearchPage(id){
      Utils.openSearchPage(id)
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.teasers-container {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.teaser-box {
  width: 280px;
  margin: 10px 0px;
}

.teaser-box:hover {
  cursor: pointer;
}

.teaser-image {
  width: 100%;
  height: 190px;
}

.teaser-title {
  text-transform: none;
}

@media all and (max-width: 720px) {
  .teaser-box{
    width: 100%;
  } 
}
</style>
