<template>
  <a class="cla-checkbox vue-ctrl" @click="onClick"
    :class="{invalid: typeof(invalid) !== 'undefined' && invalid !== false}"
  >
    <input type="checkbox" :id="uniqueId" v-model="isChecked" :checked="isChecked" :name="name" :value="value"/>
    <div class="checkmark"></div>
    <label :for="uniqueId" class="checklabel">{{label}}</label>
    <Tooltip :content="tooltip" v-if="tooltip"></Tooltip>
  </a>
</template>

<script>
import Tooltip from "./Tooltip";
import Utils from "../services/utils";

export default {
  name: "Checkbox",
  props: ["id", "name", "label", "checked", "value", "tooltip", "invalid"],
  components: {
    Tooltip
  },
  data() {
    return {
      isChecked: false,
    };
  },
  computed : {
    uniqueId () {
      return this.id || Utils.getComponentId(this);
    }
  },
  methods: {
    onClick: function(e) {
      this.isChecked = !this.isChecked;
      this.$emit("input", this.isChecked);
      this.$emit("change", { sender: this, checked: this.isChecked });
    }
  },
  mounted() {
    this.isChecked = this.$props.checked ? true : false;
  },
  watch: {
    checked (val) {
      this.isChecked = !!val;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-checkbox {
    display: flex;
    align-items: center;
    cursor: pointer;
    color: black;
    font-weight: 400;
    margin-bottom: 5px;
    position: relative;
}

.cla-checkbox .checkmark {
    width: 24px;
    min-width: 24px;
    height: 24px;
    border: 1px solid #AAAAAA;
    background-color: #EEEEEE;
    border-radius: 3px;
    margin-right: 20px;
}

.cla-checkbox input[type=checkbox]:checked+.checkmark {
    background-image:url("data:image/svg+xml;charset=utf8,%3Csvg xmlns:dc='http://purl.org/dc/elements/1.1/' xmlns:svg='http://www.w3.org/2000/svg' xmlns='http://www.w3.org/2000/svg' width='19.21315' height='18.294994' version='1.0'%3E%3Cg transform='translate(-192.905,-516.02064)'%3E%3Cpath style='fill:%23000000' d='M 197.67968,534.31563 C 197.40468,534.31208 196.21788,532.53719 195.04234,530.37143 L 192.905,526.43368 L 193.45901,525.87968 C 193.76371,525.57497 194.58269,525.32567 195.27896,525.32567 L 196.5449,525.32567 L 197.18129,527.33076 L 197.81768,529.33584 L 202.88215,523.79451 C 205.66761,520.74678 208.88522,517.75085 210.03239,517.13691 L 212.11815,516.02064 L 207.90871,520.80282 C 205.59351,523.43302 202.45735,527.55085 200.93947,529.95355 C 199.42159,532.35625 197.95468,534.31919 197.67968,534.31563 z '/%3E%3C/g%3E%3C/svg%3E");
    background-size: 26px;
    background-repeat: no-repeat;
}

.cla-checkbox .checklabel {
    font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 100;
}

.cla-checkbox > input[type=checkbox] {
    display:none;
}

.cla-checkbox > .tooltip {
  margin-top: 0;
}

.cla-checkbox.invalid > .checkmark {
  background-color: rgb(245, 201, 201);
}

.cla-checkbox.invalid > .checklabel {
  color: red;
}
</style>