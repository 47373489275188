// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf8,%3Csvg xmlns=%27http://www.w3.org/2000/svg%27 width=%27357%27 height=%27357%27%3E%3Cpath fill=%27%23aaa%27 d=%27M357 35.7L321.3 0 178.5 142.8 35.7 0 0 35.7l142.8 142.8L0 321.3 35.7 357l142.8-142.8L321.3 357l35.7-35.7-142.8-142.8z%27/%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".list-item[data-v-de4f3844]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;padding:10px 0;margin:0 0 0 0;border-top:1px solid #c9c9c9}.list-item[data-v-de4f3844]:first-of-type{border-top:none}.list-item>.list-item-name[data-v-de4f3844]{font-family:Gotham SSm A,Arial,Helvetica,sans-serif;font-size:12px;font-weight:400;color:#323232;-webkit-box-flex:1;-ms-flex-positive:1;flex-grow:1;padding-left:10px}.list-item>.list-item-remove[data-v-de4f3844]{width:12px;height:12px;background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:12px 12px;margin-left:10px;cursor:pointer;background-position:50%;background-repeat:no-repeat;padding-right:10px}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
