<template>
  <div class="cla-filepicker-container vue-ctrl" 
    :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false,invalid: typeof(invalid) !== 'undefined' && invalid !== false}" >
    <div class="label-container"><label :for="uniqueId" class="filepicker-label" v-if="label">{{label}}</label><Tooltip :content="tooltip" v-if="tooltip"></Tooltip></div>
    <div class="control-container">
      <div class="input-container">
        <input type="file" ref="fileInput" @change="onChange" :id="uniqueId">
        <input
          type="text"
          :placeholder="placeholder"
          readonly 
          :value="currentText"
        >
        <div class="vertical-separator"></div>
        <a class="browse-link" @click="onBrowse">{{browse}}</a>
      </div>
    </div>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
import Utils from "../services/utils";
export default {
  name: "FilePicker",
  props: [
    "label",
    "mandatory",
    "placeholder",
    "browseLabel",
    "tooltip",
    "invalid",
    "description",
    "validation-message"
  ],
  components: { Tooltip },
  data() {
    return {
      currentText: "",
      browse: this.browseLabel || "Browse"
    };
  },
  computed : {
    uniqueId () {
      return this.id || Utils.getComponentId(this);
    }
  },
  methods: {
    onChange() {
      let fullpath = this.$refs.fileInput.value;
      if (fullpath) {
        var startIndex = (fullpath.indexOf('\\') >= 0 ? fullpath.lastIndexOf('\\') : fullpath.lastIndexOf('/'));
        var filename = fullpath.substring(startIndex);
        if (filename.indexOf('\\') === 0 || filename.indexOf('/') === 0) {
            filename = filename.substring(1);
        }
        this.currentText = filename;
        this.$emit("input", this.currentText);
        this.$emit("change", {fileName: this.currentText, sender: this.$refs.fileInput});
      }
    },
    onBrowse () {
      this.$refs.fileInput.click();
    },
    getFileInput () {
      return this.$refs.fileInput;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-filepicker-container {
  width: 100%;
  margin-bottom: 10px;
}

.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-filepicker-container .filepicker-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-filepicker-container .filepicker-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-filepicker-container.mandatory .filepicker-label:after {
  content: "*";
}

.cla-filepicker-container .control-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  position: relative;
}

.cla-filepicker-container .input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  background-color: #e4e4e4;
  padding: 4px 16px;
  box-sizing: border-box;
  display: flex;
  align-self: center;
  align-items: center;
}

.cla-filepicker-container.invalid .input-container {
  background-color: rgb(245, 201, 201);
}

.cla-filepicker-container .input-container input {
  border: none;
  flex-grow: 1;
  background-color: #e4e4e4;
  font-size: 16px;
  line-height: 19px;
}

.cla-filepicker-container.invalid .input-container input {
  background-color: rgb(245, 201, 201);
}

.cla-filepicker-container .input-container input[type=file] {
  display: none;
}

.cla-filepicker-container input::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}

/* clears the 'X' from Internet Explorer */
.cla-filepicker-container input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.cla-filepicker-container input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
.cla-filepicker-container input[type="search"]::-webkit-search-decoration,
.cla-filepicker-container input[type="search"]::-webkit-search-cancel-button,
.cla-filepicker-container input[type="search"]::-webkit-search-results-button,
.cla-filepicker-container input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.vertical-separator {
    width:1px;
    background-color: black;
    height:32px;
}

.browse-link {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
    font-size: 14px;
    line-height: 17px;
    color: black;
    font-weight: 700;
    margin-left: 16px;
    cursor: pointer;
}

.cla-filepicker-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-filepicker-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-filepicker-container.invalid .description-container {
  display: none;
}

.cla-filepicker-container.invalid .validation-error {
  display: block;
}
</style>