<template>
  <div class="compound-search">
    <DropList :data="compounds" :placeholder="placeholder" @change="onSelect"></DropList>
  </div>
</template>

<script>
import { DropList } from "clariant-vue-forms";
import { FlatButton } from "clariant-vue-forms";
import { Labels, Config, Utils, DataLayer } from "../services";

export default {
  name: "CompoundSearch",
  components: {
    FlatButton,
    DropList
  },
  props: {
    placeholder: String,
    type: String
  },
  data() {
    return {
      labels: Labels,
      config: Config,
      compounds: null
    };
  },
  mounted () {
    let loader = Utils.showLoader(".compound-search");
        DataLayer.getCompounds(this.type).then(res => {
          this.compounds = res.data;
        }).catch (err => {
          Utils.showStatusMessage(`Unable to load compounds: ${err}!`, "red");
        }).finally (() => {
          Utils.removeLoader(loader);
        });
  },
  methods: {
    onSelect(e) {
      Utils.openSearchPage(e.value);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
