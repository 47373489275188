'use strict';

let Labels = {
    initialized: false,
    heading1: "Fermentum Elit",
    heading2: "What are you looking for?",
    intro: "Lorem ipsum dolor sit amet, consetetur sadipsEing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. ",
    productInfo: "Product info",
    productInfoTitle: "Product search",
    productInfoIntro: "Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua.",
    byProductName: "by keyword/product name",
    byIndustry: "by industry",
    byApplication: "by application",
    byCompound: "by polymer/material",
    byFunction: "by function",
    byMaterial: "by material",
    productSearchPlaceholder: "Direct products search",
    search: "Search",
    selectApplication: "Please select an application",
    selectPolymer: "Polymer / Material",
    selectFunction: "Function",
    selectMaterial: "Material",
    get (name) {
        if (this.initialized === false) this.init();
        if (this.hasOwnProperty(name)) return this[name];
        return "Undefined";
    },
    init() {
        let labelEls = document.querySelectorAll(".frgoals-params > .labels > input[type='hidden']");
        if (labelEls && labelEls.length > 0) {
            for (let i = 0; i < labelEls.length; i++) {
                const el = labelEls[i];
                this[el.name] = el.value;
            };
        }
        this.initialized = true;
    }
};

export default Labels;