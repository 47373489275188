<template>
  <div
    class="cla-datepicker-container vue-ctrl"
    :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false, invalid: typeof(invalid) !== 'undefined' && invalid !== false}"
  >
    <div class="label-container">
      <div class="picker-label" v-if="label">{{label}}</div>
      <Tooltip :content="tooltip" v-if="tooltip"></Tooltip>
    </div>
    <div class="control-container">
      <div class="input-container">
        <embedded-date-picker v-model="currentValue" :class="{disabled}" 
          :placeholder="placeholder" 
          @input="onChange" @selected="onChange" :format="dateFormat"></embedded-date-picker>
      </div>
    </div>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
import EmbeddedDatePicker from "vuejs-datepicker";

export default {
  name: "DatePicker",
  props: ["value","label", "mandatory", "tooltip", "disabled", "format", "placeholder", "invalid","description",
    "validation-message"],
  components: { Tooltip, EmbeddedDatePicker },
  data() {
    return {
      currentValue: this.$props.value,
      dateFormat: this.$props.format || "dd.MM.yyyy"
    };
  },
  mounted() {
    if (this.value) {
      console.log("Setting date value on mount");
      this.currentValue = this.value;
    }
  },
  destroyed() {
    
  },
  methods: {
    onChange() {
      this.$emit("input", this.currentValue);
      this.$emit("change", this.currentValue);
    }
  },
  watch: {
    value(val) {
      console.log("Setting date value");
      this.currentValue = val;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
.cla-datepicker-container.vue-ctrl {
  width: 100%;
  margin-bottom: 10px;
}

.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-datepicker-container.vue-ctrl .picker-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-datepicker-container.vue-ctrl .picker-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-datepicker-container.vue-ctrl.mandatory .picker-label:after {
  content: "*";
}

.cla-datepicker-container.vue-ctrl .control-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  position: relative;
}

.cla-datepicker-container.vue-ctrl .input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  background-color: #e4e4e4;
  padding: 4px 16px;
  box-sizing: border-box;
  display: flex;
  align-self: center;
}

.cla-datepicker-container.invalid .input-container {
  background-color: rgb(245, 201, 201);
}

.cla-datepicker-container.vue-ctrl .input-container .vdp-datepicker {
  display: flex;
  width: 100%;
}

.cla-datepicker-container.vue-ctrl .input-container .vdp-datepicker > div:nth-child(1) {
  display: flex;
  flex-grow:1;
}

.cla-datepicker-container.vue-ctrl .input-container .vdp-datepicker .vdp-datepicker__calendar {
  top:35px;
  left:0;
}

.cla-datepicker-container.vue-ctrl .input-container .vdp-datepicker input{
  border: none;
  flex-grow: 1;
  background-color: #e4e4e4;
  font-size: 16px;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cla-datepicker-container.vue-ctrl.invalid .input-container .vdp-datepicker input{
  background-color: rgb(245, 201, 201);
}

.cla-datepicker-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-datepicker-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-datepicker-container.invalid .description-container {
  display: none;
}

.cla-datepicker-container.invalid .validation-error {
  display: block;
}
</style>