<template>
  <div class="cla-notebox-container vue-ctrl" 
    :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false, invalid: typeof(invalid) !== 'undefined' && invalid !== false}" >
    <div class="label-container"><label :for="uniqueId" class="notebox-label" v-if="label">{{label}}</label><Tooltip :content="tooltip" v-if="tooltip"></Tooltip></div>
    <div class="control-container">
      <div class="input-container">
        <textarea
          :placeholder="placeholder"
          v-model="currentText"
          :rows="rows"
          @change="onChange" :maxlength="maxlength"
          :id="uniqueId"
        ></textarea>
      </div>
    </div>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
import Utils from "../services/utils";
export default {
  name: "NoteBox",
  props: [
    "label",
    "mandatory",
    "value",
    "placeholder",
    "tooltip",
    "rows",
    "invalid",
    "description",
    "validation-message",
    "maxlength"
  ],
  components: { Tooltip },
  data() {
    return {
      currentText: this.value
    };
  },
  computed : {
    uniqueId () {
      return this.id || Utils.getComponentId(this);
    }
  },
  methods: {
    onChange() {
      this.$emit("input", this.currentText);
      this.$emit("change", this.currentText);
    },
  },
  watch: {
    value (val) {
      this.currentText = val;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-notebox-container {
  width: 100%;
  margin-bottom: 10px;
}

.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-notebox-container .notebox-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-notebox-container .notebox-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-notebox-container.mandatory .notebox-label:after {
  content: "*";
}

.cla-notebox-container .control-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
}

.cla-notebox-container .input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: auto;
  background-color: #e4e4e4;
  padding: 4px 16px;
  box-sizing: border-box;
  display: flex;
  align-self: center;
}

.cla-notebox-container.invalid .input-container {
  background-color: rgb(245, 201, 201);
}

.cla-notebox-container .input-container textarea {
  border: none;
  flex-grow: 1;
  background-color: #e4e4e4;
  font-size: 16px;
  line-height: 19px;
  resize: none;
  overflow: auto;
}

.cla-notebox-container.invalid .input-container textarea{
  background-color: rgb(245, 201, 201);
}

.cla-notebox-container input::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}

.cla-notebox-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-notebox-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-notebox-container.invalid .description-container {
  display: none;
}

.cla-notebox-container.invalid .validation-error {
  display: block;
}
</style>