<template>
  <button class="button vue-ctrl" :class="{gray: disabled || gray, blue: blue && !disabled, white, 'inline-left':inlineLeft, 'inline-right':inlineRight, disabled}" @click="onClick">
    {{label}}
  </button>
</template>

<script>
export default {
  name: 'FlatButton',
  props: {
    label: String, 
    gray: Boolean,
    blue: Boolean,
    white: Boolean,
    inlineLeft: Boolean,
    inlineRight: Boolean,
    disabled: Boolean
  },
  data (){
      return {
        
      }
  },
  methods: {
    onClick() {
      if (!this.disabled) {
        this.$emit("click", {sender: this});
      }
    }   
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
    .button.disabled {
      color: #dddddd;
    }
</style>