<template>
  <div class="application-search">
    <div id="applist-container" v-if="appCategories">
      <DropList v-for="(cat, index) in appCategories" :key="cat.id" :data="appCategories[index].applications" :placeholder="cat.name" @change="onAppChanged"></DropList>
    </div>
  </div>
</template>

<script>
import { DropList } from "clariant-vue-forms";
import { Labels, Config, DataLayer, Utils } from "../services";

export default {
  name: "ApplicationSearch",
  components: {
    DropList
  },
  data() {
    return {
      labels: Labels,
      config: Config,
      appCategories: null
    };
  },
  mounted () {
    let loader = Utils.showLoader(".tab-container");
    DataLayer.getApplications().then(res => {
      this.appCategories = res.data;
    }).catch (err => {
      Utils.showStatusMessage(`Unable to load applications: ${err}!`, "red");
    }).finally (() => {
      Utils.removeLoader(loader);
    });
  },
  methods: {
    onAppChanged(app) {
      console.log(`Showing products belonging to ${app.label}...`);
      Utils.openSearchPage(app.value);
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#applist-container {
  min-height: 225px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

#applist-container > div{
  width: 48%;
}

@media all and (max-width: 720px) {
  #applist-container > div{
    width: 100%;
  } 
}
</style>
