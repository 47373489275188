<template>
  <div class="cla-richtext-container vue-ctrl"
    :class="{ mandatory: typeof (mandatory) !== 'undefined' && mandatory !== false }">
    <div class="label-container">
      <div class="notebox-label" v-if="label">{{ label }}</div>
      <Tooltip :content="tooltip" v-if="tooltip"></Tooltip>
    </div>
    <div class="control-container" :style="'height:' + controlHeight">
      <div class="input-container" ref="editor-wrapper">
        <vue-editor v-model="currentText" :editorToolbar="editorToolbar" @text-change="onChange"></vue-editor>
      </div>
    </div>
    <div class="description-container">{{ description }}</div>
    <div class="validation-error">{{ validationMessage }}</div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
import { VueEditor, Quill } from "vue2-editor";



const _defaultToolbar = [
  [
    {
      header: [false, 1, 2, 3, 4, 5, 6]
    }
  ],
  ["bold", "italic", "underline", "strike"], // toggled buttons
  [
    {
      align: ""
    },
    {
      align: "center"
    },
    {
      align: "right"
    },
    {
      align: "justify"
    }
  ],
  [
    {
      list: "ordered"
    },
    {
      list: "bullet"
    },
    {
      list: "check"
    }
  ],
  [
    {
      indent: "-1"
    },
    {
      indent: "+1"
    }
  ], // outdent/indent
  [
    {
      color: []
    },
    {
      background: []
    }
  ], // dropdown with defaults from theme
  ["link"],
  ["clean"] // remove formatting button
];

export default {
  name: "RichTextBox",
  props: ["value", "label", "mandatory", "tooltip", "toolbar", "height", "description",
    "validation-message"],
  components: { Tooltip, VueEditor },
  data() {
    return {
      currentText: this.$props.value,
      editorToolbar: this.$props.toolbar || _defaultToolbar,
      controlHeight: this.height || "300px",
      editorOptions: {
        modules: {
          table: true
        }
      }
    };
  },
  mounted() {
    this.addTooltips();
    this.addPasteHandler();
  },
  methods: {
    addPasteHandler() {
      if (this.$refs["editor-wrapper"]) {
        let self = this;
        this.$refs["editor-wrapper"].addEventListener("paste", (e) => {
          setTimeout(() => {
            let qlEditor = this.$refs["editor-wrapper"].querySelector(".ql-editor");
            if (qlEditor) {
              self.currentText = qlEditor.innerHTML;
              this.$emit("input", this.currentText);
              this.$emit("change", this.currentText);        
            }
          }, 500);
        });
      } else {
        console.info("Could not configure paste handler for rich text");
      }
    },
    onChange() {
      if (this.externalUpdate) {
        //the value has been changed from the parent. we're not sending the change event.
        this.externalUpdate = false;
        return;
      }
      this.$emit("input", this.currentText);
      this.$emit("change", this.currentText);
    },
    addTooltips() {
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-bold", "Bold");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-italic", "Italic");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-underline", "Underline");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-strike", "Strikeout");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-align[value='']", "Align left");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-align[value='center']", "Align center");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-align[value='right']", "Align right");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-align[value='justify']", "Justify");

      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-list[value='ordered']", "Ordered list");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-list[value='bullet']", "Bullet list");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-list[value='check']", "Checkbox list");

      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-indent[value='-1']", "Indent left");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-indent[value='+1']", "Indent right");

      this.setTooltip(".cla-richtext-container.vue-ctrl span.ql-color", "Text color");
      this.setTooltip(".cla-richtext-container.vue-ctrl span.ql-background", "Background color");

      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-link", "Insert link");
      this.setTooltip(".cla-richtext-container.vue-ctrl .ql-clean", "Remove all formating");
    },
    setTooltip(sel, tooltip) {
      if (!sel) return;
      let els = document.querySelectorAll(sel);
      if (!els || els.length === 0) return;
      for (let i = 0; i < els.length; i++) {
        let el = els[i];
        el.setAttribute("title", tooltip);
      }
    }
  },
  watch: {
    value(val) {
      console.log("Rich text value changed in parent")
      this.externalUpdate = true;
      this.currentText = val;
    }
  }
};
</script>

<style>
.cla-richtext-container.vue-ctrl {
  width: 100%;
  margin-bottom: 10px;
}

.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-richtext-container.vue-ctrl .notebox-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-richtext-container.vue-ctrl .notebox-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-richtext-container.vue-ctrl.mandatory .notebox-label:after {
  content: "*";
}

.cla-richtext-container.vue-ctrl .control-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  position: relative;
}

.cla-richtext-container.vue-ctrl .input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background-color: #e4e4e4;
  box-sizing: border-box;
  display: flex;
  align-self: center;
}

.cla-richtext-container.vue-ctrl .input-container .quillWrapper {
  flex-grow: 1;
}

.cla-richtext-container.vue-ctrl .input-container .ql-toolbar.ql-snow {
  border: none;
  border-bottom: 1px solid #ccc;
}

.cla-richtext-container.vue-ctrl .input-container .ql-container.ql-snow {
  border: none;
}

.cla-richtext-container.vue-ctrl .ql-snow .ql-editor {
  height: calc(100% - 47px) !important;
}

.cla-richtext-container.vue-ctrl .ql-snow .ql-editor h1 {
  padding-bottom: 4px;
  font-family: 'Mercury SSm A', 'Mercury SSm B', serif;
  font-size: 30px;
  line-height: 30px;
  font-weight: 300;
  font-style: normal;
}

.cla-richtext-container.vue-ctrl .ql-snow .ql-editor h2 {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-weight: 700;
  font-size: 27px;
  line-height: 30px;
  font-style: normal;
  padding-bottom: 20px;
}

.cla-richtext-container.vue-ctrl .ql-snow .ql-editor h3 {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 28px;
  line-height: 34px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.cla-richtext-container.vue-ctrl .ql-snow .ql-editor h4 {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
  text-transform: uppercase;
}

.cla-richtext-container.vue-ctrl .ql-snow .ql-editor h5 {
  font-family: 'Gotham SSm A', 'Gotham SSm B', sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  font-style: normal;
}

.cla-richtext-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 15px;
  padding-top: 2px;
}

.cla-richtext-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-richtext-container.invalid .description-container {
  display: none;
}

.cla-richtext-container.invalid .validation-error {
  display: block;
}
</style>