<template>
  <div class="cla-list-container vue-ctrl" :class="{mandatory: typeof(mandatory) !== 'undefined' && mandatory !== false}">
    <div class="label-container">
      <div class="list-label" v-if="label">{{label}}</div>
      <Tooltip :content="tooltip" v-if="tooltip"></Tooltip>
    </div>
    <div class="control-container" :style="'height:' + controlHeight">
      <ul>
        <ListBoxItem
          v-for="li in listData"
          :key="li.id"
          :item="li"
          :readonly="readonly"
          :uppercase="uppercase"
          @remove="onRemoveItem"
        ></ListBoxItem>
      </ul>
      <div class="placeholder" v-if="!listData || listData.length === 0">{{placeholder}}</div>
    </div>
    <div class="description-container">{{description}}</div><div class="validation-error">{{validationMessage}}</div>
  </div>
</template>

<script>
import ListBoxItem from "./ListBoxItem";
import Tooltip from "./Tooltip";
export default {
  name: "ListBox",
  props: [
    "label",
    "mandatory",
    "height",
    "tooltip",
    "data",
    "readonly",
    "uppercase",
    "placeholder",
    "description",
    "validation-message",
    "invalid"
  ],
  components: {
    ListBoxItem,
    Tooltip
  },
  data() {
    return {
      listData: this.$props.data || [],
      controlHeight: this.height || "auto"
    };
  },
  mounted() {},
  methods: {
    onRemoveItem(item) {
      if (!item) return;
      this.$emit("remove", {sender: this, item});
    }
  },
  watch: {
    data(val) {
      this.listData = val;
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-list-container {
  width: 100%;
  margin-bottom: 10px;
}

.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-list-container .list-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-list-container .list-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-list-container.mandatory .list-label:after {
  content: "*";
}

.cla-list-container .control-container {
  width: 100%;
  position: relative;
  box-sizing: border-box;
  background-color: #e4e4e4;
  padding: 0px 16px 0px 16px;
  overflow-y: auto;
  min-height: 100px;
}

.cla-list-container .control-container > ul {
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.cla-list-container .control-container > .placeholder {
  text-align: center;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  color: #888;
  font-size: 13px;
  top:40%;
  left: 0;
  right: 0;
  margin: auto;
  position:absolute;
}

.cla-list-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  padding-top: 2px;
}

.cla-list-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height:15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-list-container.invalid .description-container {
  display: none;
}

.cla-list-container.invalid .validation-error {
  display: block;
}
</style>