import Config from "./config";
import axios from 'axios';

var DataLayer = {
    init() {

    },
    getApplications() {
        return getResource("applications.json");
    },
    getCompounds(type) {
        return getResource(`${type}.json`);
    },
};

function getResource(file){
    let url = `${Config.resUrl}/${file}`;
        if (!url) return Promise.reject("The application seems to be misconfigured. Please contact your administrator.");
        let headers = {};
        /*if (Config.authenticationToken) {
            headers["Authorization"] = "Basic " + Config.authenticationToken;
        }*/

        return axios.get(url, { headers });
};

export default DataLayer;