// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("data:image/svg+xml;charset=utf8,%3Csvg xmlns:dc=%27http://purl.org/dc/elements/1.1/%27 xmlns:svg=%27http://www.w3.org/2000/svg%27 xmlns=%27http://www.w3.org/2000/svg%27 width=%2719.21315%27 height=%2718.294994%27 version=%271.0%27%3E%3Cg transform=%27translate%28-192.905,-516.02064%29%27%3E%3Cpath style=%27fill:%23000000%27 d=%27M 197.67968,534.31563 C 197.40468,534.31208 196.21788,532.53719 195.04234,530.37143 L 192.905,526.43368 L 193.45901,525.87968 C 193.76371,525.57497 194.58269,525.32567 195.27896,525.32567 L 196.5449,525.32567 L 197.18129,527.33076 L 197.81768,529.33584 L 202.88215,523.79451 C 205.66761,520.74678 208.88522,517.75085 210.03239,517.13691 L 212.11815,516.02064 L 207.90871,520.80282 C 205.59351,523.43302 202.45735,527.55085 200.93947,529.95355 C 199.42159,532.35625 197.95468,534.31919 197.67968,534.31563 z %27/%3E%3C/g%3E%3C/svg%3E", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".cla-checkbox[data-v-2c64264d]{display:-webkit-box;display:-ms-flexbox;display:flex;-webkit-box-align:center;-ms-flex-align:center;align-items:center;cursor:pointer;color:#000;font-weight:400;margin-bottom:5px;position:relative}.cla-checkbox .checkmark[data-v-2c64264d]{width:24px;min-width:24px;height:24px;border:1px solid #aaa;background-color:#eee;border-radius:3px;margin-right:20px}.cla-checkbox input[type=checkbox]:checked+.checkmark[data-v-2c64264d]{background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");background-size:26px;background-repeat:no-repeat}.cla-checkbox .checklabel[data-v-2c64264d]{font-family:Gotham SSm A,Arial,Helvetica,sans-serif;font-size:14px;font-weight:100}.cla-checkbox>input[type=checkbox][data-v-2c64264d]{display:none}.cla-checkbox>.tooltip[data-v-2c64264d]{margin-top:0}.cla-checkbox.invalid>.checkmark[data-v-2c64264d]{background-color:#f5c9c9}.cla-checkbox.invalid>.checklabel[data-v-2c64264d]{color:red}", ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
