<template>
  <div class="slider vue-ctrl">
    <div class="label-container"><div class="slider-label" v-if="label">{{label}}</div><Tooltip :content="tooltip" v-if="tooltip"></Tooltip></div>
    <vue-slider :min="min" :max="max" :interval="interval" v-model="currentValue" :value="value" ref="innerSlider" @drag-end="onDragEnd"></vue-slider>
  </div>
</template>

<script>
import vueSlider from "vue-slider-component";
import Tooltip from "./Tooltip";

export default {
  name: "Slider",
   model: {
            prop: "value",
            event: "input"
        },
  props: ["min", "max", "label", "value", "interval", "tooltip"],
  data() {
    return {
      currentValue: this.value
    };
  },
  methods: {
    setCurrentValue(val) {
      console.log("Explicitly setting slider value");
      this.$refs.innerSlider.setValue(val);
    },
    onDragEnd() {
      /*this.$emit("input", this.currentValue);
      this.$emit("change", this.currentValue);*/
    }
  },
  mounted () {
    if (this.value) {
      this.currentValue = this.value;
    } else {
      this.currentValue = [this.min, this.max];
    }
  },
  watch: {
    currentValue(val) {
      this.$emit("input", this.currentValue);
      this.$emit("change", this.currentValue);
    },
    value (val) {
      this.currentValue = val;
      this.$refs.innerSlider.setValue(val);
    }
  },
  components: {
    vueSlider, Tooltip
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.slider {
  margin-bottom: 20px;
}
.vue-ctrl .label-container {
  display: flex;
  align-items: center;
  margin-bottom: 50px;
}
.slider .slider-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.slider .tooltip {
  margin-top: 0;
}
</style>