<template>
  <div
    class="cla-droplist-container vue-ctrl"
    :class="{
      mandatory: typeof mandatory !== 'undefined' && mandatory !== false,
      invalid: typeof invalid !== 'undefined' && invalid !== false,
    }"
    v-on:blur="onBlur"
    ref="dropdown"
  >
    <select :id="uniqueId">
      <option
        v-for="item in data"
        :val="item[valueField]"
        :key="item[valueField]"
        >{{ item[labelField] }}</option
      >
    </select>
    <div class="label-container">
      <label class="select-label" v-if="label" :for="uniqueId">{{ label }}</label>
      <Tooltip :content="tooltip" v-if="tooltip"></Tooltip>
    </div>
    <div class="control-container">
      <div class="input-container">
        <input
          type="search"
          :placeholder="placeholder"
          :readonly="typeof filterable === 'undefined' || disabled"
          @click="toggleDropDown"
          :class="{ disabled }"
          @keydown="onKeyDown"
          @keyup="onKeyUp"
          autocomplete="off"
          v-model="currentSelection"
          aria-label="input-controler-container"
        />
        <a class="cla-icon-link cla-icon-link-drop" @click="toggleDropDown"></a>
      </div>
      <ul class="options-container" :class="{ open: dropDownVisible }">
        <li
          v-for="(item, index) in visibleData"
          :val="item[valueField]"
          :key="item[valueField]"
          @click="selectValue(item)"
          :class="{highlighted: index === highlightedIndex}"
        >
          {{ item[labelField] }}
        </li>
      </ul>
    </div>
    <div class="description-container">{{ description }}</div>
    <div class="validation-error">{{ validationMessage }}</div>
  </div>
</template>

<script>
import Tooltip from "./Tooltip";
import Utils from "../services/utils";
export default {
  name: "DropList",
  model: {
    prop: "value",
    event: "input",
  },
  props: [
    "label",
    "mandatory",
    "filterable",
    "data",
    "value",
    "labels",
    "values",
    "placeholder",
    "tooltip",
    "disabled",
    "invalid",
    "description",
    "validation-message",
    "custom-entry-label"
  ],
  components: { Tooltip },
  data() {
    return {
      labelField: this.labels || "name",
      valueField: this.values || "id",
      dropDownVisible: false,
      currentSelection: "",
      highlightedIndex: -1, 
      visibleData: [],
    };
  },
  mounted() {
    this.visibleData = Array.isArray(this.data) ? [...this.data] : [];
    document.addEventListener("click", this.onClickOutside);
    document.addEventListener("touchstart", this.onClickOutside);
    this.$root.$on("dropdown-displayed", (sender) => {
      if (sender !== this) this.isVisible = false;
    });
    if (this.value && this.data) {
      console.log("Setting default selection when mounted.");
      const val = this.value;
      let entry = this.data.find((i) => {
        return i[this.valueField] === val;
      });
      if (entry) {
        this.currentSelection = entry[this.labelField];
      } else {
        this.currentSelection = "";
      }
      if (typeof this.filterable !== 'undefined' && !this.disabled && this.customEntryLabel) {
        let el = {};
        el[this.labelField] = this.customEntryLabel;
        el[this.valueField] = "---custom--entry---";
        this.visibleData.push(el);
      }
    }
  },
  destroyed() {
    document.removeEventListener("click", this.onClickOutside);
    document.removeEventListener("touchstart", this.onClickOutside);
  },
  computed : {
    uniqueId () {
      return this.id || Utils.getComponentId(this);
    }
  },
  methods: {
    toggleDropDown() {
      if (this.disabled) return;
      this.dropDownVisible = !this.dropDownVisible;
      if (this.dropDownVisible) {
        this.$root.$emit("dropdown-displayed", this);
      }
    },
    selectValue(item) {
      this.dropDownVisible = false;
      this.$emit("input", item ? item[this.valueField] : "");
      this.$emit("change", {
        sender: this,
        value: item ? item[this.valueField] : "",
        label: item ? item[this.labelField] : "",
      });
      if (item && item[this.valueField] && item[this.valueField] === "---custom--entry---") {
        this.$emit("custom", {sender: this});
      }
      //this.currentSelection = item ? item[this.labelField] : "";
    },
    onBlur() {
      this.dropDownVisible = false;
    },
    onClickOutside(e) {
      //e.stopPropagation()
      if (!this.dropDownVisible) return;
      if (!this.$refs.dropdown.contains(e.target)) {
        this.dropDownVisible = false;
      }
    },
    onFocusTextbox(e) {
      if (typeof this.filterable !== "undefined") {
        this.dropDownVisible = true;
        this.$root.$emit("dropdown-displayed", this);
      }
    },
    onBlurTextbox(e) {
      if (typeof this.filterable !== "undefined") {
        const newFocusEl = e.explicitOriginalTarget || document.activeElement;
        const container = this.$refs.dropdown;
        if (!this.isDescendant(container, newFocusEl)) {
          this.dropDownVisible = false;
        }
      }
    },
    onKeyDown(e) {
      if (typeof this.filterable !== "undefined") {
        if (e.keyCode === 27) {
          e.preventDefault();
          this.dropDownVisible = false;
        }
        else if (e.keyCode === 9) {
          this.dropDownVisible = false;
        }
      }
    },
    onKeyUp(e) {
      if (typeof this.filterable !== "undefined") {
        const ul = this.$refs.dropdown.querySelector(".options-container");
        const container = this.$refs.dropdown;
        if (e.keyCode === 40) {
          //arrowDown
          e.preventDefault();
          if (!this.dropDownVisible) {
            this.dropDownVisible = true;
            this.$root.$emit("dropdown-displayed", this);
            this.highlightedIndex = this.visibleData.findIndex(x => x[this.labelField] === this.currentSelection)
          } else {
            if (this.highlightedIndex < this.visibleData.length - 1) this.highlightedIndex++;
          }
        } else if (e.keyCode === 38) {
          //arrowUp
          e.preventDefault();
          if (this.highlightedIndex > 0) this.highlightedIndex--;
        } else if (e.keyCode === 13 ) {
          //enter
          if (this.highlightedIndex > -1 && this.highlightedIndex < this.visibleData.length) {
            this.selectValue(this.visibleData[this.highlightedIndex]);
          }
          container.focus();
        } else if (e.keyCode === 27) {
          //esc
          e.preventDefault();
          this.dropDownVisible = false;
        } else if (
          this.isPrintable(e.keyCode) ||
          e.keyCode === 46 ||
          e.keyCode === 8
        ) {
          //printable, delete or backspace
          this.filterDropDownOptions();
        }
      }
    },
    isDescendant(parent, child) {
      var node = child.parentNode;
      while (node != null) {
        if (node == parent) {
          return true;
        }
        node = node.parentNode;
      }
      return false;
    },
    filterDropDownOptions() {
      let ss = this.currentSelection.toLowerCase();
      this.visibleData = [];
      this.data.map((e) => {
        if (
          e[this.labelField] &&
          e[this.labelField].toLowerCase().indexOf(ss) !== -1 
        ) {
          this.visibleData.push(e);
        }
      });
      this.highlightedIndex = -1;

      if (typeof this.filterable !== 'undefined' && !this.disabled && this.customEntryLabel) {
        let el = {};
        el[this.labelField] = this.customEntryLabel;
        el[this.valueField] = "---custom--entry---";
        this.visibleData.push(el);
      }
    },
    isPrintable(keycode) {
      var valid =
        (keycode > 47 && keycode < 58) || // number keys
        keycode == 32 ||
        keycode == 13 || // spacebar & return key(s) (if you want to allow carriage returns)
        (keycode > 64 && keycode < 91) || // letter keys
        (keycode > 95 && keycode < 112) || // numpad keys
        (keycode > 185 && keycode < 193) || // ;=,-./` (in order)
        (keycode > 218 && keycode < 223); // [\]' (in order)

      return valid;
    },
  },
  watch: {
    value(val) {
      if (!val) {
        this.currentSelection = "";
        return;
      }
      let entry = this.data.find((i) => {
        return i[this.valueField] === val;
      });
      if (entry) {
        this.currentSelection = entry[this.labelField];
      } else if (typeof this.filterable !== 'undefined' && !this.disabled && this.customEntryLabel && val === "---custom--entry---") {
        this.currentSelection = this.customEntryLabel;
      } else {
        this.currentSelection = "";
      }
    },
    data(val) {
      console.log(
        "The drop list data has changed. Trying to set default value."
      );
      this.visibleData = Array.isArray(val) ? [...val] : [];
      const currentValue = this.value;
      if (!val || !currentValue) {
        this.currentSelection = "";
        return;
      }
      let entry = val.find((i) => {
        return i[this.valueField] === currentValue;
      });
      if (entry) {
        this.currentSelection = entry[this.labelField];
      } else {
        this.currentSelection = "";
      }

      if (typeof this.filterable !== 'undefined' && !this.disabled && this.customEntryLabel) {
        let el = {};
        el[this.labelField] = this.customEntryLabel;
        el[this.valueField] = "---custom--entry---";
        this.visibleData.push(el);
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.cla-droplist-container {
  width: 100%;
  margin-bottom: 10px;
}

.cla-droplist-container > select {
  display: none;
}

.label-container {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
}

.cla-droplist-container .select-label {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 18px;
  font-weight: 500;
}

.cla-droplist-container .select-label:after {
  content: " ";
  white-space: pre;
  color: red;
  vertical-align: super;
  font-size: 80%;
}

.cla-droplist-container.mandatory .select-label:after {
  content: "*";
}

.cla-droplist-container .control-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 40px;
  position: relative;
}

.cla-droplist-container .input-container {
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 40px;
  background-color: #e4e4e4;
  padding: 4px 16px;
  box-sizing: border-box;
  display: flex;
  align-self: center;
}

.cla-droplist-container.invalid .input-container {
  background-color: rgb(245, 201, 201);
}

.cla-droplist-container .input-container input {
  border: none;
  flex-grow: 1;
  background-color: #e4e4e4;
  font-size: 16px;
  line-height: 19px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cla-droplist-container.invalid .input-container input {
  background-color: rgb(245, 201, 201);
}

.cla-droplist-container .input-container input.disabled::placeholder {
  color: #bbbbbb;
}

.cla-droplist-container .input-container input:not(readonly) {
  cursor: pointer;
}

.cla-droplist-container input::-webkit-search-cancel-button {
  position: relative;
  right: 20px;
}

/* clears the 'X' from Internet Explorer */
.cla-droplist-container input[type="search"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.cla-droplist-container input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
.cla-droplist-container input[type="search"]::-webkit-search-decoration,
.cla-droplist-container input[type="search"]::-webkit-search-cancel-button,
.cla-droplist-container input[type="search"]::-webkit-search-results-button,
.cla-droplist-container
  input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

.cla-droplist-container .input-container a.cla-icon-link {
  padding: 10px 0 10px 34px;
  background-repeat: no-repeat;
  background-size: 24px 24px;
  background-position-y: 50%;
  background-position-x: 10px;
  color: black;
}

.cla-droplist-container .input-container a.cla-icon-link-drop {
  background-image: url("data:image/svg+xml;charset=utf8,%3C?xml version='1.0' encoding='iso-8859-1'?%3E%3Csvg xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='255px' height='255px' viewBox='0 0 255 255'%3E%3Cg%3E%3Cg id='arrow-drop-down'%3E%3Cpolygon points='0,63.75 127.5,191.25 255,63.75'/%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
  background-size: 20px 20px;
}

.cla-droplist-container .options-container {
  position: absolute;
  top: 40px;
  width: 100%;
  background-color: #e4e4e4;
  left: 0;
  border-top: 1px solid #d0d0d0;
  padding: 0px 0;
  z-index: 100;
  display: none;
  max-height: 300px;
  overflow-y: auto;
}

.cla-droplist-container .options-container.open {
  display: block;
  border: 1px solid #d0d0d0;
}

.cla-droplist-container .options-container li {
  padding: 4px 16px;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 14px;
  cursor: pointer;
}

.cla-droplist-container .options-container li.highlighted {
  background-color: rgba(255,255,255,0.5);
}

.cla-droplist-container .description-container {
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 15px;
  padding-top: 2px;
}

.cla-droplist-container .validation-error {
  color: #d22c2c;
  font-family: "Gotham SSm A", Arial, Helvetica, sans-serif;
  font-size: 12px;
  line-height: 15px;
  font-weight: 500;
  padding-top: 2px;
  display: none;
}

.cla-droplist-container.invalid .description-container {
  display: none;
}

.cla-droplist-container.invalid .validation-error {
  display: block;
}
</style>
